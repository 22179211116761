import BaseApiServices from '@/services/BaseApiServices';

class EvaluationsAPIService extends BaseApiServices {
    async getMyCurrentState({ gameId, evaluatedUserId }) {
        const url = `/games/evaluations/getMyCurrentState/${gameId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().get(url);
    }

    async getCompanyRankings({ signal = null, defaultGameRanking = null }) {
        let url = `games/statistic/companyRankings`;

        if (defaultGameRanking) {
            url += '?defaultGameRanking=' + defaultGameRanking;
        }

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getRankingUserDetails({ companyId, gameId, signal = null }) {
        const url = `admin/games/statistic/ranking/${companyId}/${gameId}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getUsersToEvaluate(signal = null) {
        const url = `games/evaluations/getUsersToEvaluate`;
        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getEvaluatingInfo(signal = null) {
        const url = `games/evaluations/getEvaluatingInfo`;
        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async finishGame({ gameId, evaluatedUserId }) {
        const url = `/games/evaluations/finishGame/${gameId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().post(url);
    }

    /*
     ************ Cards ***********
     */

    async getChoicesFromCard({ cardId, signal = null }) {
        const url = `games/evaluations/cards/${cardId}/choices`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async answerQuestion({ gameId, cardId, evaluatedUserId, answer }) {
        const url = `/games/evaluations/answer/${gameId}/${cardId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().post(url, {
            answer
        });
    }
}

export default new EvaluationsAPIService();
